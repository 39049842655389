/* eslint-disable */
import React from 'react';
import {getPublicImagePath} from '@nutastic/nutastic-react-app';
import PageSlider from '../PageSlider';
import {ReactSVG} from 'react-svg';
import MusicPlayer from "../MusicPlayer";

const ComingSoonPage = () => {
    const componentClass = 'coming-soon-page';
    //const { xl : isLargeScreen } = useBreakpoints();
    const slides = [
        getPublicImagePath('d2m/Doing2Much--Cover3.jpg'),
        getPublicImagePath('d2m/Doing2Much--Cover1.jpg'),
        getPublicImagePath('basketball/covers/main.jpg'),
        getPublicImagePath('basketball/covers/1.jpg'),
        getPublicImagePath('basketball/covers/4.jpg'),
        getPublicImagePath('basketball/covers/list.jpg'),
        getPublicImagePath('summerFunUsa/summerFun.jpg'),
        getPublicImagePath('summerFunUsa/cover-2.jpg'),
        getPublicImagePath('summerFunUsa/summerFun--45.jpg')
    ];

    return <React.Fragment>
        <header className={`${componentClass}__header`}>
            <ReactSVG src={ getPublicImagePath('araytv-logo.svg') } />
        </header>
        <PageSlider slides={slides} />
        <div className="labelIcons">
            <a href="https://music.apple.com/us/album/mr-basketball/1758593270" target="_blank"><img src={getPublicImagePath('label--apple.png')} alt="Summer Fun U.S.A. on Apple Music by Quistar" /></a>
            <a href="https://open.spotify.com/album/2CJ7vJdoaqOsOcRnEfYEj0?si=Gzy9WD5gSYacPmVcwWcK0g" target="_blank"><img src={getPublicImagePath('label--spotify.png')} alt="Summer Fun U.S.A. on Spotify by Quistar" /></a>
            <a href="https://youtu.be/t0MLI3tUX18?si=3Dgdip0M1sNOnBbo" target="_blank"><img src={getPublicImagePath('label--youtube.png')} alt="Summer Fun U.S.A. on Youtube by Quistar" /></a>
        </div>
        <MusicPlayer />
    </React.Fragment>
};

export default ComingSoonPage;
