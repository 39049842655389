import React, { useEffect, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { getPublicImagePath } from '@nutastic/nutastic-react-app';

const MusicPlayer = () => {
    const componentClass = 'musicPlayer';
    const [currentTrack, setTrackIndex] = useState(0);

    const playlist = [
        { name: 'Doing 2 Much (ft. Sauce Walka)', src: getPublicImagePath('d2m/D2M.mp3'), cover: getPublicImagePath('d2m/d2m--512.png') },
        { name: 'Walk In The Park', src: getPublicImagePath('basketball/music/1--walkInThePark.mp3'), cover: getPublicImagePath('basketball/sizes/mrball--512.png') },
        { name: 'Favors For Mayors', src: getPublicImagePath('basketball/music/2--mayor.mp3'), cover: getPublicImagePath('basketball/sizes/mrball--512.png') },
        { name: 'Foundations', src: getPublicImagePath('basketball/music/3--foundations.mp3'), cover: getPublicImagePath('basketball/sizes/mrball--512.png') },
        { name: 'Franklin Ave. Saint', src: getPublicImagePath('basketball/music/6--franklin.mp3'), cover: getPublicImagePath('basketball/sizes/mrball--512.png') },
        { name: 'Gatorade Player Of The Year', src: getPublicImagePath('basketball/music/9--gatorade.mp3'), cover: getPublicImagePath('basketball/sizes/mrball--512.png') },
        { name: 'Don\'t Forget Your Lunch', src: getPublicImagePath('summerFunUsa/music/1--lunchBox.mp3'), cover: getPublicImagePath('summerFunUsa/covers/1.png') },
        { name: 'When A Bee Stings a Bird', src: getPublicImagePath('summerFunUsa/music/2--bird.mp3'), cover: getPublicImagePath('summerFunUsa/covers/2.png') },
        { name: 'King Supreme Superior', src: getPublicImagePath('summerFunUsa/music/3--supreme.mp3'), cover: getPublicImagePath('summerFunUsa/covers/3.png') },
        { name: 'Karate Kid', src: getPublicImagePath('summerFunUsa/music/4--karateKid.mp3'), cover: getPublicImagePath('summerFunUsa/covers/4.png') },
        { name: 'Don\'t Be Afraid To Be Great', src: getPublicImagePath('summerFunUsa/music/5--beGreat.mp3'), cover: getPublicImagePath('summerFunUsa/covers/5.png') },
        { name: '45', src: getPublicImagePath('summerFunUsa/music/6--45.mp3'), cover: getPublicImagePath('summerFunUsa/covers/6.png') },
        { name: 'London, Milan', src: getPublicImagePath('summerFunUsa/music/7--london.mp3'), cover: getPublicImagePath('summerFunUsa/covers/7.png') }
    ];

    useEffect(() => {
        if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: playlist[currentTrack].name,
                artist: 'Quistar',
                album: 'Mr. Basketball',
                artwork: [
                    { src: playlist[currentTrack].cover, sizes: '512x512', type: 'image/png' },
                    { src: getPublicImagePath('basketball/sizes/mrball--512.png'), sizes: '512x512', type: 'image/png' },
                    { src: getPublicImagePath('basketball/sizes/mrball--256.png'), sizes: '256x256', type: 'image/png' }
                ]
            });
        }
    }, [currentTrack]);

    const handleClickPrevious = () => {
        setTrackIndex(currentTrack => currentTrack > 0 ? currentTrack - 1 : 0);
    };

    const handleClickNext = () => {
        setTrackIndex(currentTrack => currentTrack < playlist.length - 1 ? currentTrack + 1 : 0);
    };

    const handleEnd = () => {
        setTrackIndex(currentTrack => currentTrack < playlist.length - 1 ? currentTrack + 1 : 0);
    };

    return <div className={componentClass}>
        <AudioPlayer
            volume="0.5"
            autoPlay={false}
            src={ playlist[currentTrack].src }
            showJumpControls={false}
            showSkipControls={true}
            showDownloadProgress={false}
            loop={false}
            layout={'horizontal-reverse'}
            customVolumeControls={[]}
            customAdditionalControls={[]}
            showFilledVolume={false}
            onClickNext={handleClickNext}
            onClickPrevious={handleClickPrevious}
            onEnded={handleEnd}
        />
    </div>;
};

export default MusicPlayer;
